<template>
  <div class="BJS">
    <Header></Header>

    <div class="banner">
      <div class="banner_bx">
        <div class="banner_bx_left">
          <img src="https://images.qudonghao.cn//uploads/20210617/247a2a52b8317320ac1eb40143335060.jpg" alt="" />
        </div>
        <div class="banner_bx_right">
          <div class="Headlines">M1 版本iPad Pro发布 性能飞跃最大2TB</div>
          <div class="Headlines_bottom" v-for="(item, index) in Headlines" :key="index">
            <div v-if="index < 5" class="Headlines_bottom_list">
              <div class="xiaodian">·</div>
              {{ item.center }}
            </div>
            <div v-if="index < 5">{{ item.data }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="Advertisement">
      <img src="../assets/img/changtu.png" alt="" />
    </div>

    <div class="Bottom_list">
      <div class="Bottom_list_bx">
        <div class="Bottom_list_bx_left">
          <div class="swiper-container czp">
            <div class="swiper-wrapper">
              <div class="swiper-slide" :class=" {active : actives == index}" v-for="(item,index) in Swiper_List"
                :key="index" @click="tab(index)">{{item}}</div>

            </div>
            <div class="swiper-button-prev">＜</div>
            <div class="swiper-button-next">＞</div>
          </div>
          <div class="Display_list">
            <div class="Display_list_box" v-for="(item, index) in List" :key="index">
              <div class="Display_list_box_top">
                <div class="Display_list_box_top_left">
                  {{ item.statement_title }}
                </div>
                <div v-if="item.status == 1" class="Display_list_box_top_right">处理中</div>
                <div v-if="item.status == 2" class="Display_list_box_top_right1">已完成</div>
              </div>
              <div class="Display_list_box_center">
                <div class="Display_list_box_center_top">
                  <div class="Display_list_box_center_right">
                    <span class="Display_list_box_center_left">[投诉对象]</span>{{ item.business_name }}
                  </div>
                </div>
                <div class="Display_list_box_center_top">
                  <div class="Display_list_box_center_right">
                    <span class="Display_list_box_center_left">[投诉理由]</span>{{ item.complaint_reason }}
                  </div>
                </div>
              </div>
              <div class="Display_list_box_bottom">
                <div class="Display_list_box_bottom_left">
                  <img src="https://weixin.qudong.com/public/app_upload/head_5fd2cee2bbcd72.92431931.png" alt="" />
                  <div class="name">{{ item.nickname }}</div>
                  <div class="data">{{ item.create_time }}</div>
                </div>
                <div class="Display_list_box_bottom_right">
                  <img src="../assets/img/dianzan_icon.png" alt="" />
                  <span>{{ item.like_num }}</span>
                  <img src="../assets/img/pinglun_icon.png" alt="" />
                  <span>{{ item.comment_num }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Bottom_list_bx_right">
          <!-- 我要投诉 -->
          <img class="tousu_img" src="../assets/img/tousu.png" alt="" />
          <img class="paihang_img" src="../assets/img/paihang.png" alt="" />
          <!-- 排行榜 -->
          <van-tabs class="zheshizidingyide" color="#1f72d9">
            <van-tab title="红榜" class="paihangbang">
              <div class="paihang_list" v-for="(item, index) in Ranking" :key="index">
                <div class="paihangbang_left">
                  <img v-if="index == 0" class="paihangbang_left_left" src="../assets/img/one.png" alt="" />
                  <img v-if="index == 1" class="paihangbang_left_left" src="../assets/img/two.png" alt="" />
                  <img v-if="index == 2" class="paihangbang_left_left" src="../assets/img/three.png" alt="" />
                  <div v-if="index > 2" class="paihang_num">
                    {{ index + 1 }}
                  </div>
                  <img class="paoihang_icon" :src="item.img" alt="" />
                </div>
                <div class="paihangbang_right">{{ item.name }}</div>
              </div>
            </van-tab>
            <van-tab title="黑榜" class="paihangbang">
              <div class="paihang_list" v-for="(item, index) in Rankings" :key="index">
                <div class="paihangbang_left">
                  <img v-if="index == 0" class="paihangbang_left_left" src="../assets/img/one.png" alt="" />
                  <img v-if="index == 1" class="paihangbang_left_left" src="../assets/img/two.png" alt="" />
                  <img v-if="index == 2" class="paihangbang_left_left" src="../assets/img/three.png" alt="" />
                  <div v-if="index > 2" class="paihang_num">
                    {{ index + 1 }}
                  </div>
                  <img class="paoihang_icon" :src="item.img" alt="" />
                </div>
                <div class="paihangbang_right">{{ item.name }}</div>
              </div>
            </van-tab>
          </van-tabs>
          <!-- 热门投诉 -->
          <div class="Hot_complaints">
            <div class="title">热门投诉</div>
            <div class="Hot_List" v-for="(item,index) in Hot_List" :key="index">
              <img :src="item.img" alt="" />
              <div class="Hot_List_title">
                {{item.title}}
              </div>
              <div class="Hot_List_data">{{item.data}}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="Footer">
      ©驱动中国 2005-2019 版权所有 京ICP备11013097号 广播电视节目许可证： (京) 字第07618号 京公网安备:11010802028348号 高新企业认证编号： GR201811007312 CMSTOP
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Axios from "axios";
import { complaint_index } from "../api/api.js";
import Swiper from "swiper";
import { Tab, Tabs } from "vant";

export default {
  name: "index",
  data() {
    return {
      arr: [],
      isiOS: "", //初始一个空值
      isAndroid: "", //初始一个空值
      text: "过滤器",
      List: [],
      Swiper_List: [
        "全部",
        "汽车品牌",
        "电商品牌",
        "共享服务",
        "旅游出行",
        "视听娱乐",
        "教育培训",
        "电子消费拼",
        "汽车品牌",
        "电商品牌",
        "共享服务",
        "旅游出行",
        "视听娱乐",
        "教育培训",
        "电子消费拼",
      ],
      Ranking: [
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
        { img: require("../assets/img/paihang_img.png"), name: "比亚迪汽车" },
      ],
      Rankings: [
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
        { img: require("../assets/img/paihang_img.png"), name: "兰博基尼" },
      ],
      Hot_List: [
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "方和第三方黑凤凰额外花覅热火佛寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "第三方黑凤凰额外花覅热火佛寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "方黑凤凰额外花覅热火佛寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "凰额外花覅热火佛寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "外花覅热火佛寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "覅热火佛寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "热火佛寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "火佛寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "寺额而发",
          data: "05-01",
        },
        {
          img: "http://qudonghao.cn/uploads/20200609/122f046767921bcf7b16bc13ad54971f.jpg",
          title: "寺额而发",
          data: "05-01",
        },
      ],
      Headlines: [
        {
          center:
            "分不到社保费菲妮迪是给对方能感觉到你付款见过比你东风科技吧高端复刻发货低首付ID人不负奇热比热那个",
          data: "05-10",
        },
        {
          center:
            "分不到社保费菲妮迪是给对方能感觉到你付款见过比你东风科技吧高端复刻发货低首付ID人不负奇热比热那个",
          data: "05-10",
        },
        {
          center:
            "分不到社保费菲妮迪是给对方能感觉到你付款见过比你东风科技吧高端复刻发货低首付ID人不负奇热比热那个",
          data: "05-10",
        },
        {
          center:
            "分不到社保费菲妮迪是给对方能感觉到你付款见过比你东风科技吧高端复刻发货低首付ID人不负奇热比热那个",
          data: "05-10",
        },
        {
          center:
            "分不到社保费菲妮迪是给对方能感觉到你付款见过比你东风科技吧高端复刻发货低首付ID人不负奇热比热那个",
          data: "05-10",
        },
        {
          center:
            "分不到社保费菲妮迪是给对方能感觉到你付款见过比你东风科技吧高端复刻发货低首付ID人不负奇热比热那个",
          data: "05-10",
        },
        {
          center:
            "分不到社保费菲妮迪是给对方能感觉到你付款见过比你东风科技吧高端复刻发货低首付ID人不负奇热比热那个",
          data: "05-10",
        },
      ],
      actives: 0,
    };
  },

  components: {
    Header,
  },

  computed: {},

  methods: {
    complaint_index() {
      let tokens = localStorage.getItem("token");
      if (tokens) {
        complaint_index({ type: 0 }).then((res) => {
          //console.log(res.data.data);
          this.List = res.data.data.data;
        });
      } else {
        Axios({
          method: "get",
          url: "https://tsadmin.qudong.com/api/complaint_index",
          params: {
            type: 0,
          },
        }).then((res) => {
          //console.log(res.data.data);
          this.List = res.data.data.data;
        });
      }
    },
    prev() {
      this.$refs.swiper.$swiper.slidePrev();
    },
    next() {
      this.$refs.swiper.$swiper.slideNext();
    },
    tab(index) {
      //console.log(index);
      this.actives = index;
      if (index == 0) {
        this.$router.push({ path: "/" });
      } else {
        this.$router.push({ path: "/category/" + index });
      }
    },

    request() {
      let that = this;
      // var param=new URLSearchParams;
      // param.append("cid","65816");
      Axios({
        method: "get",
        url: "https://weixin.qudong.com/api/pingce/index",
        param: {
          limit: 1,
          page: 1,
        },
      }).then((res) => {
        //console.log(res);
        that.arr = res;
      });
    },
  },
  created() {
    var that = this;
    this.actives = this.$route.params.index;

    //console.log(this.actives);
  },

  mounted() {
    this.request();
    this.complaint_index();
    new Swiper(".czp", {
      // direction: 'vertical', // 垂直切换选项
      slidesPerView: 9,
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
      },

      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // 如果需要滚动条
      scrollbar: {
        el: ".swiper-scrollbar",
      },
    });
  },
};
</script>


<style scoped>
.BJS {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
}

.banner {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}
.banner_bx {
  width: 1200px;
  height: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.banner_bx_left {
  width: 49%;
  height: 100%;
}
.banner_bx_left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner_bx_right {
  width: 49%;
  height: 100%;
  background: #fff;
}
.Headlines {
  width: 94%;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ccc;
  padding-left: 15px;
  margin-left: 10px;
  margin-top: 15px;
  font-size: 20px;
  font-weight: 550;
}
.Headlines_bottom {
  width: 94%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  margin-left: 10px;
  margin-top: 7px;
  position: relative;
}
.Headlines_bottom_list {
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.xiaodian {
  position: absolute;
  left: 0px;
  top: -3px;
  font-size: 50px;
  color: #1f72d9;
}
.Advertisement {
  margin: 20px 0;
  width: 100%;
}
.Advertisement img {
  width: 1200px;
  margin: 0 auto;
}
.Bottom_list {
  width: 100%;
}
.Bottom_list_bx {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.Bottom_list_bx_left {
  width: 900px;
  background: #fff;
  margin-bottom: 100px;
}
.Bottom_list_bx_right {
  width: 280px;
}
.czp {
  width: 96%;
  height: 50px;
  line-height: 50px;
  padding-left: 35px;
}
.swiper-button-prev {
  background-size: 25px 25px;
  font-size: 30px;
  background: #fff;
  width: 33px;
  height: 53px;
  left: -3px;
  top: 23px;
  color: #1f72d9;
}
.swiper-button-next {
  background-size: 25px 25px;
  font-size: 30px;
  background: #fff;
  width: 33px;
  height: 53px;
  right: -2px;
  top: 23px;
  color: #1f72d9;
}
.Display_list {
  width: 100%;
}
.Display_list_box {
  width: 100%;
  height: 180px;
  border-bottom: 1px solid #e8e8e8;
}
.Display_list_box_top:nth-child(1) {
  border-top: 1px solid #e5e5e5;
}
.Display_list_box_top {
  padding: 10px 20px 0 20px;
  display: flex;
  justify-content: space-between;
}
.Display_list_box_top_left {
  font-size: 20px;
}
.Display_list_box_top_right {
  background: #1f72d9;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fff;
}
.Display_list_box_top_right1 {
  background: orange;
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  color: #fff;
}
.Display_list_box_center {
  padding: 0 20px;

  margin-top: 10px;
}
.Display_list_box_center_top {
  display: flex;
}
.Display_list_box_center_left {
  color: #1f72d9;
  width: 77px;
  margin-right: 5px;
}
.Display_list_box_center_right {
  width: 650px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Display_list_box_bottom {
  display: flex;
  padding: 0 20px;
  margin-top: 10px;
}
.Display_list_box_bottom_left {
  width: 50%;
  display: flex;
  align-items: center;
}

.Display_list_box_bottom_left img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
}
.Display_list_box_bottom_left .name {
  margin-right: 30px;
}
.Display_list_box_bottom_left .data {
  color: #999999;
}
.Display_list_box_bottom_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Display_list_box_bottom_right img {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-left: 30px;
}
.Display_list_box_bottom_right span {
}
.Bottom_list_bx_right .tousu_img {
  width: 100%;
}
.Bottom_list_bx_right .paihang_img {
  width: 100%;
  margin-top: 30px;
}
.zheshizidingyide {
  background: #fff;
  padding-bottom: 20px;
}
.paihangbang {
  border-top: 1px solid #ccc;
}
.paihang_list {
  padding: 10px 0;
  width: 85%;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8;
  display: flex;

  align-items: center;
}
>>> .van-tabs__line {
  height: 1px !important;
}
.paihang_list div:nth-child(1) {
  display: flex;
}
.paihangbang_left {
  display: flex;
  align-items: center;
}
.paihangbang_left_left {
  width: 23px;
  height: 29px;
}
.paihang_list .paoihang_icon {
  margin-left: 30px;
}
.paihang_list .paihang_num {
  width: 20px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #ccc;
  border-radius: 5px;
  color: #fff;
}
.paihangbang_right {
  margin-left: 20px;
}
.Hot_complaints {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
  background: #fff;
}
.Hot_complaints .title {
  width: 100%;
  font-size: 18px;
  font-weight: 550;
  color: #1f72d9;
  padding: 15px 0;
  margin-left: 4%;
  margin-bottom: 15px;
  border-bottom: 1px solid #e8e8e8;
}

.Hot_complaints .Hot_List {
  width: 92%;
  height: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Hot_complaints .Hot_List img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.Hot_List_title {
  width: 150px;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.Hot_List_data {
  font-size: 14px;
  color: #999999;
  cursor: pointer;
}
.Footer {
  width: 100%;
  height: 40px;
  margin: 0 auto;
  color: #fff;
  font-size: 14px;
  background: #242222;
  height: 44px;
  line-height: 44px;
  text-align: center;
}
.swiper-slide {
  cursor: pointer;
}
.swiper-slide:nth-child(1) {
  width: 60px !important;
}
.active {
  color: #2397ee;
}
</style>
